(function($) {
  "use strict";
  var Page, bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };
  Page = (function() {
    function Page() {
      this.action = bind(this.action, this);
      this.controller = bind(this.controller, this);
    }
    Page.prototype.controller = function() {
      return $('meta[name=psj]').attr('controller');
    };
    Page.prototype.action = function() {
      return $('meta[name=psj]').attr('action');
    };
    return Page;
  })();
  var page = new Page();

  // back to top - start
  // --------------------------------------------------
  $(window).scroll(function() {
    if ($(this).scrollTop() > 200) {
      $('#backtotop:hidden').stop(true, true).fadeIn();
    } else {
      $('#backtotop').stop(true, true).fadeOut();
    }
  });
  $(function() {
    $("#scroll").on('click', function() {
      $("html,body").animate({
        scrollTop: $("#thetop").offset().top
      }, "slow");
      return false
    })
  });


  // back to top - end
  // --------------------------------------------------



  // preloader - start
  // --------------------------------------------------
  // $(window).on('load turbolinks:load', function(){
  //   $('#preloader').fadeOut('slow',function(){$(this).remove();});
  //   $('.close-btn, .overlay').on('click', function () {
  //     $('#sidebar-menu').removeClass('active');
  //     $('.overlay').removeClass('active');
  //   });
  //
  //   $('#sidebar-collapse').on('click', function () {
  //     $('#sidebar-menu').addClass('active');
  //     $('.overlay').addClass('active');
  //   });
  //   init_js();
  // });
  // preloader - end
  // --------------------------------------------------

  $(document).on('turbolinks:load', function() {
    if (!(page.controller() === 'homes'))
      return;
    $(document).ready(function() {
      $('#preloader').fadeOut('slow',function(){$(this).remove();});

      $(".js-modal-connect-button button").on("click", function() {
        $(".error-form-login").css("display", "block");
      });
    });
  });

  $(document).on('turbolinks:load', function() {
    if (!(page.controller() === 'sells' && page.action() === 'index'))
      return;
    $(document).ready(function() {
      $('select').niceSelect();
      $(".submit-sell").on("click", function(e) {
        submit_sell();
      });
    });
  });

  $(document).on('turbolinks:load', function() {
    if (!(page.controller() === 'choices' && page.action() === 'index'))
      return;
    $(document).ready(function() {
      $("li.choice-gestion").on("click", function(e) {
        console.log("MAINTENANCE")
        location.href = "/maintenance";
      });
      $("li.choice-immobilier").on("click", function(e) {
        console.log("immobilier")
        location.href = "/immobilier";
      });
    });
  });

  $(document).on('turbolinks:load', function() {
    $(".btn-subscribe").on("click", function(e) {
      submit_subscribe();
    });
    if (!(page.controller() === 'buys' && page.action() === 'show'))
      return;
    $(document).ready(function() {
      $('#details-image-carousel').owlCarousel({
        items:1,
        nav:true,
        loop:true,
        margin:10,
        dots:false,
        center:true,
        autoplay:true,
        smartSpeed:1000,
        autoplayTimeout:5000,
        autoplayHoverPause:true
      });

      $(".property-contact-btn").on("click", function(e) {
        submit_contact();
      });

    });
  });

  $(document).on('turbolinks:load', function() {
    if (!(page.controller() === 'contacts' && page.action() === 'index'))
      return;
    $(document).ready(function() {
      $(".submit-btn-area .custom-btn-bordered").on("click", function(e) {
        console.log("TOTO");
        submit_contact_page();
      });
    });
  });

  $(document).on('turbolinks:load', function() {
    if (!(page.controller() === 'homes' && page.action() === 'index'))
      return;
    $(document).ready(function() {
      $('select').niceSelect();
      $('#testimonial-carousel').owlCarousel({
        nav:true,
        loop:true,
        margin:30,
        dots:false,
        center:true,
        autoplay:true,
        smartSpeed:1000,
        autoplayTimeout:4000,
        autoplayHoverPause:true,
        responsive:{
          0:{
            items:1
          },
          600:{
            items:2
          },
          1000:{
            items:2
          },
          1100:{
            items:3
          },
          1920:{
            items:3
          }
        }
      });
    });
  });

  $(document).on('turbolinks:load', function() {
    $(document).ready(function() {
      $(window).scroll(function() {
        if ($(this).scrollTop() > 200) {
          $('#backtotop:hidden').stop(true, true).fadeIn();
        } else {
          $('#backtotop').stop(true, true).fadeOut();
        }
      });
      $(function() {
        $("#scroll").on('click', function() {
          $("html,body").animate({
            scrollTop: $("#thetop").offset().top
          }, "slow");
          return false
        })
      });

      $('#preloader').fadeOut('slow',function(){$(this).remove();});
      $('.close-btn, .overlay').on('click', function () {
        $('#sidebar-menu').removeClass('active');
        $('.overlay').removeClass('active');
      });

      $('#sidebar-collapse').on('click', function () {
        $('#sidebar-menu').addClass('active');
        $('.overlay').addClass('active');
      });
      var parallaxItem = $('.parallax-window');
      if (parallaxItem.length > 0) {
        parallaxItem.parallax({imageSrc: $(".parallax-window")[0].getAttribute("data-image-src")});
      }

    });
  });



  // mobile menu - start
  // --------------------------------------------------
  $(document).ready(function () {
    $('.close-btn, .overlay').on('click', function () {
      $('#sidebar-menu').removeClass('active');
      $('.overlay').removeClass('active');
    });

    $('#sidebar-collapse').on('click', function () {
      $('#sidebar-menu').addClass('active');
      $('.overlay').addClass('active');
    });
  });
  // mobile menu - end
  // --------------------------------------------------


  function init_js() {
    $('select').niceSelect();
    // main-slider-1 - start
    // --------------------------------------------------
    $('#main-slider-1').owlCarousel({
      items:1,
      margin:0,
      nav:false,
      loop:true,
      autoplay:true,
      smartSpeed:1000,
      autoplayTimeout:5000,
      autoplayHoverPause:true,
    });

    var dot = $('#main-slider-1 .owl-dot');
    dot.each(function() {
      var index = $(this).index() + 1;
      if(index < 10){
        $(this).html('0').append(index);
      }else{
       $(this).html(index);
     }
   });
    // main-slider-1 - end
    // --------------------------------------------------





    // testimonial-carousel - start
    // --------------------------------------------------
    $('#testimonial-carousel').owlCarousel({
      nav:true,
      loop:true,
      margin:30,
      dots:false,
      center:true,
      autoplay:true,
      smartSpeed:1000,
      autoplayTimeout:4000,
      autoplayHoverPause:true,
      responsive:{
        0:{
          items:1
        },
        600:{
          items:2
        },
        1000:{
          items:2
        },
        1100:{
          items:3
        },
        1920:{
          items:3
        }
      }
    });
    // testimonial-carousel - end
    // --------------------------------------------------





    // details-image-carousel - start
    // --------------------------------------------------

    // details-image-carousel - end
    // --------------------------------------------------





    // testimonial-carousel - start
    // --------------------------------------------------
    $(document).ready(function() {
      var bigimage = $("#testimonial-carousel-2");
      var thumbs = $("#thumbs-nav");
      var syncedSecondary = true;

      bigimage
      .owlCarousel({
        items: 1,
        nav: false,
        loop: true,
        dots: false,
        // autoplay: true,
        smartSpeed: 1000,
        autoplayHoverPause:true
        // navText: [
        // '<i class="fa fa-arrow-left" aria-hidden="true"></i>',
        // '<i class="fa fa-arrow-right" aria-hidden="true"></i>'
        // ]
      })
      .on("changed.owl.carousel", syncPosition);

      thumbs
      .on("initialized.owl.carousel", function() {
        thumbs
        .find(".owl-item")
        .eq(0)
        .addClass("current");
      })
      .owlCarousel({
        items: 4,
        margin:10,
        nav: false,
        slideBy: 4,
        dots: false,
        smartSpeed: 1000
        // navText: [
        // '<i class="fa fa-arrow-left" aria-hidden="true"></i>',
        // '<i class="fa fa-arrow-right" aria-hidden="true"></i>'
        // ],
      })
      .on("changed.owl.carousel", syncPosition2);

      function syncPosition(el) {
        //if loop is set to false, then you have to uncomment the next line
        //var current = el.item.index;

        //to disable loop, comment this block
        var count = el.item.count - 1;
        var current = Math.round(el.item.index - el.item.count / 2 - 0.5);

        if (current < 0) {
          current = count;
        }
        if (current > count) {
          current = 0;
        }
        //to this
        thumbs
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");
        var onscreen = thumbs.find(".owl-item.active").length - 1;
        var start = thumbs
        .find(".owl-item.active")
        .first()
        .index();
        var end = thumbs
        .find(".owl-item.active")
        .last()
        .index();

        if (current > end) {
          thumbs.data("owl.carousel").to(current, 100, true);
        }
        if (current < start) {
          thumbs.data("owl.carousel").to(current - onscreen, 100, true);
        }
      }

      function syncPosition2(el) {
        if (syncedSecondary) {
          var number = el.item.index;
          bigimage.data("owl.carousel").to(number, 100, true);
        }
      }

      thumbs.on("click", ".owl-item", function(e) {
        e.preventDefault();
        var number = $(this).index();
        bigimage.data("owl.carousel").to(number, 300, true);
      });
    });
    // testimonial-carousel - end
    // --------------------------------------------------





    // side bar featured carousel - start
    // --------------------------------------------------
    $('#sb-featured-carousel').owlCarousel({
      items:1,
      nav:false,
      loop:true,
      margin:0,
      dots:true,
      autoplay:true,
      smartSpeed:1000,
      autoplayTimeout:4000,
      autoplayHoverPause:true
    });
    // side bar featured carousel - end
    // --------------------------------------------------





    // featurd property carousel - start
    // --------------------------------------------------
    $('#featurd-property-carousel').owlCarousel({
      nav:true,
      margin:30,
      loop:true,
      dots:false,
      merge:true,
      center: true,
      smartSpeed:1000,
      stagePadding: 60,
      responsive:{
        0:{
          items:1,
          stagePadding: 0,
        },
        600:{
          items:2,
          stagePadding: 0,
        },
        1024:{
          items:2
        },
        1200:{
          items:3
        },
        1400:{
          items:4
        },
        1920:{
          items:5
        }
      }
    });

    $('#featurd-property-carousel-2').owlCarousel({
      nav:true,
      margin:30,
      loop:true,
      dots:false,
      merge:true,
      center: true,
      smartSpeed:1000,
      responsive:{
        0:{
          items:1
        },
        600:{
          items:2
        },
        1000:{
          items:2
        },
        1200:{
          items:3
        },
        1920:{
          items:3
        }
      }
    });
    // featurd property carousel - end
    // --------------------------------------------------





    // video popup - start
    // --------------------------------------------------
    $('.popup-video').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,

      fixedContentPos: false
    });
    // video popup - end
    // --------------------------------------------------





    // Counter - start
    // --------------------------------------------------
    $('.count-text').counterUp({
      delay: 10,
      time: 1000
    });
    // Counter - end
    // --------------------------------------------------





    // masoney grid layout - start
    // --------------------------------------------------
    // var $grid = $('.grid').imagesLoaded( function() {
    //   $grid.masonry({
    //     itemSelector: '.grid-item',
    //     percentPosition: true,
    //     columnWidth: '.grid-sizer'
    //   });
    // });
    // masoney grid layout - end
    // --------------------------------------------------





    // header-section (auto-hide) - Start
    // --------------------------------------------------
    var mainHeader = $('.auto-hide'),
    secondaryNavigation = $('.cd-secondary-nav'),
      //this applies only if secondary nav is below intro section
      belowNavHeroContent = $('.sub-nav-hero'),
      headerHeight = mainHeader.height();

      //set scrolling variables
      var scrolling = false,
      previousTop = 0,
      currentTop = 0,
      scrollDelta = 10,
      scrollOffset = 150;

      $(window).on('scroll', function(){
        if( !scrolling ) {
          scrolling = true;
          (!window.requestAnimationFrame)
          ? setTimeout(autoHideHeader, 250)
          : requestAnimationFrame(autoHideHeader);
        }
      });

      $(window).on('resize', function(){
        headerHeight = mainHeader.height();
      });

      function autoHideHeader() {
        var currentTop = $(window).scrollTop();

        ( belowNavHeroContent.length > 0 )
        ? checkStickyNavigation(currentTop) // secondary navigation below intro
        : checkSimpleNavigation(currentTop);

        previousTop = currentTop;
        scrolling = false;
      }

      function checkSimpleNavigation(currentTop) {
      //there's no secondary nav or secondary nav is below primary nav
      if (previousTop - currentTop > scrollDelta) {
          //if scrolling up...
          mainHeader.removeClass('is-hidden');
        } else if( currentTop - previousTop > scrollDelta && currentTop > scrollOffset) {
          //if scrolling down...
          mainHeader.addClass('is-hidden');
        }
      }

      function checkStickyNavigation(currentTop) {
      //secondary nav below intro section - sticky secondary nav
      var secondaryNavOffsetTop = belowNavHeroContent.offset().top - secondaryNavigation.height() - mainHeader.height();

      if (previousTop >= currentTop ) {
          //if scrolling up...
          if( currentTop < secondaryNavOffsetTop ) {
            //secondary nav is not fixed
            mainHeader.removeClass('is-hidden');
            secondaryNavigation.removeClass('fixed slide-up');
            belowNavHeroContent.removeClass('secondary-nav-fixed');
          } else if( previousTop - currentTop > scrollDelta ) {
            //secondary nav is fixed
            mainHeader.removeClass('is-hidden');
            secondaryNavigation.removeClass('slide-up').addClass('fixed');
            belowNavHeroContent.addClass('secondary-nav-fixed');
          }

        } else {
          //if scrolling down...
          if( currentTop > secondaryNavOffsetTop + scrollOffset ) {
            //hide primary nav
            mainHeader.addClass('is-hidden');
            secondaryNavigation.addClass('fixed slide-up');
            belowNavHeroContent.addClass('secondary-nav-fixed');
          } else if( currentTop > secondaryNavOffsetTop ) {
            //once the secondary nav is fixed, do not hide primary nav if you haven't scrolled more than scrollOffset
            mainHeader.removeClass('is-hidden');
            secondaryNavigation.addClass('fixed').removeClass('slide-up');
            belowNavHeroContent.addClass('secondary-nav-fixed');
          }

        }
      };
    // header-section (auto-hide) - end
    // --------------------------------------------------





    // sticky menu - start
    // --------------------------------------------------
    var headerId = $(".sticky-header");
    $(window).on('scroll' , function() {
      var amountScrolled = $(window).scrollTop();
      if ($(this).scrollTop() > 80) {
        headerId.removeClass("not-stuck");
        headerId.addClass("stuck");
      } else {
        headerId.removeClass("stuck");
        headerId.addClass("not-stuck");
      }
    });
    // sticky menu - end
    // --------------------------------------------------





    // google map - start
    // --------------------------------------------------
    function isMobile() {
      return ('ontouchstart' in document.documentElement);
    }

    function init_gmap() {
      if ( typeof google == 'undefined' ) return;
      var options = {
        center: [1.2960841, 103.8458455],
        zoom: 14,
        styles: [
        {elementType: 'geometry', stylers: [{color: '#eaeaea'}]},
        {elementType: 'labels.text.stroke', stylers: [{color: '#ffffff'}]},
        {elementType: 'labels.text.fill', stylers: [{color: '$pure-black'}]},
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{color: '#d59563'}]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{color: '#61605e'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{color: '#cbe99f'}]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{color: '#98786d'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{color: '#ffffff'}]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{color: '#39c2f8'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{color: '#fedd96'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{color: '#eeca83'}]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{color: '#965c28'}]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{color: '#fef5b6'}]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{color: '#f1e0ca'}]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{color: '#a1cafe'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{color: '$pure-black'}]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{color: '#ffffff'}]
        }
        ],
        mapTypeControl: true,
        mapTypeControlOptions: {
          style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
        },
        navigationControl: true,
        scrollwheel: false,
        streetViewControl: true,
      }

      if (isMobile()) {
        options.draggable = false;
      }

      $('#google-map').gmap3({
        map: {
          options: options
        },
        marker: {
          latLng: [1.2960841, 103.8458455],
          // options: { icon: 'assets/img/map.png' }
        }
      });
    }
    init_gmap();
    // google map - end
    // --------------------------------------------------
  }

  // Init js for submit form
  function submit_subscribe() {
    var email_val = $(".subscribe-email").val();

    $.ajax({
      method: "POST",
      url: '/subscribes',
      data: {subscribe: {email: email_val}},
      dataType: 'script'
    }).done(function (response) {
      document.querySelector(".notif-subscribe").setAttribute("class", "alert alert-success notif-subscribe notif-appear");
      setTimeout(function(){
        $(".subscribe-email").val("");
        document.querySelector(".notif-subscribe").setAttribute("class", "alert alert-success notif-subscribe notif-disappear");
      }, 5000);

    }).fail(function(response)  {
      console.log("[SUBSCRIBES]: FAILED");
    });
  }

  function submit_contact() {
    var email_val = $("#contact-email").val();
    var name_val = $("#contact-name").val();
    var tel_val = $("#contact-tel").val();
    var message_val = $("#contact-message").val();

    $.ajax({
      method: "POST",
      url: '/contact',
      data: {contact: {email: email_val, full_name: name_val, phone: tel_val, description: message_val }},
      dataType: 'json'
    }).done(function (response) {
      document.querySelector(".notif-buy-contact").setAttribute("class", "alert alert-success notif-buy-contact notif-appear");
      setTimeout(function(){
        $("#contact-email").val("");
        $("#contact-name").val("");
        $("#contact-tel").val("");
        $("#contact-message").val("");
        document.querySelector(".notif-buy-contact").setAttribute("class", "alert alert-success notif-buy-contact notif-disappear");
      }, 5000);

    }).fail(function(response)  {
      console.log("[CONTACT]: FAILED");
    });
  }

  function submit_sell() {
    var address_val = $("#sells-address").val();
    var property_type_val = $("#sells-property-type").val();
    var square_surface_val = $("#sells-square-surface").val();
    var phone_val = $("#sells-phone").val();
    var email_val = $("#sells-email").val();
    var name_val = $("#sells-name").val();
    var message_val = $("#sells-message").val();

    $.ajax({
      method: "POST",
      url: '/vendre',
      data: {sell: {email: email_val, full_name: name_val, phone: phone_val, description: message_val, city: address_val, property_type: property_type_val, square_surface: square_surface_val}},
      dataType: 'json'
    }).done(function (response) {
      document.querySelector(".notif-sell").setAttribute("class", "alert alert-success notif-sell notif-appear");
      setTimeout(function(){
        $("#sells-address").val("");
        $("#sells-property-type").val("");
        $("#sells-square-surface").val("");
        $("#sells-phone").val("");
        $("#sells-email").val("");
        $("#sells-name").val("");
        $("#sells-message").val("");
        document.querySelector(".notif-sell").setAttribute("class", "alert alert-success notif-sell notif-disappear");
      }, 5000);

    }).fail(function(response)  {
      console.log("[CONTACT]: FAILED");
    });
  }

  function submit_contact_page() {
    var email_val = $("#contact-email").val();
    var name_val = $("#contact-name").val();
    var tel_val = $("#contact-tel").val();
    var message_val = $("#contact-message").val();
    var subject_val = $("#contact-subject").val();

    $.ajax({
      method: "POST",
      url: '/contact',
      data: {contact: {email: email_val, full_name: name_val, phone: tel_val, description: message_val, subject: subject_val }},
      dataType: 'json'
    }).done(function (response) {
      document.querySelector(".notif-contact").setAttribute("class", "alert alert-success notif-contact notif-appear");
      setTimeout(function(){
        $("#contact-email").val("");
        $("#contact-name").val("");
        $("#contact-tel").val("");
        $("#contact-message").val("");
        $("#contact-subject").val("");
        document.querySelector(".notif-contact").setAttribute("class", "alert alert-success notif-contact notif-disappear");
      }, 5000);

    }).fail(function(response)  {
      console.log("[CONTACT]: FAILED");
    });
  }


})(jQuery);
