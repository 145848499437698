/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)



require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("bootstrap")


require("./template/owl.carousel.min.js")
require("./template/magnific-popup.min.js")
require("./template/nice-select.min.js")
require("./template/parallax.min.js")
//require("./template/waypoints.min.js")
require("./template/jquery.counterup.min.js")
require("./template/masonry.pkgd.min.js")
require("./template/imagesloaded.pkgd.min.js")
//require("./template/jquery.mCustomScrollbar.js")

require("./init.js")
require("./gmaps.js")
require("./custom.js")
require("./custom_beta.js")





console.log('Hello World from Webpacker')
